import React from "react";
import NavigationBar from "../components/NavigationBar";
import Layout from "../components/Layout";
import HeaderCard from "../components/HeaderCard";
import Footer from "../components/Footer";
import CourseCardCourses from "../components/CourseCardCourses";
import {graphql, useStaticQuery} from "gatsby";
import Seo from "../components/Seo";


const Courses = () => {
    const courses = useStaticQuery(graphql`
    query PublishedCourses {
      data: allContentJson(
        filter: {published: {eq: true}}
        sort: {fields: title, order: ASC}) {
        edges {
          node {
            title
            shortDescription
            courseType
            contentFormats
            slug
            category
            published
          }
        }
      }
    }
    `)

    const bgColours = ["orange", "teal", "blue", "brown"];

    let courseCards = courses.data.edges.map(function(course, i) {
        return (<CourseCardCourses course={course.node} bgColour={bgColours[i % bgColours.length]} key={i}></CourseCardCourses>);
    })

    return (
        <Layout>
            <Seo title="FlatPack FinTech - Courses"/>
            <NavigationBar />
            <HeaderCard title="Courses" tagline="Your next product starts here." />
            {courseCards}
            <Footer />
        </Layout>
    )
}

export default Courses