import React from "react";
import "../styles/global.css";


function Tag(props) {
    let tagClasses = "rounded-full px-[0.75rem] py-[0.375rem] text-sm font-semibold max-w-fit inline-block mr-2 ";
    if (props.textColour) {
        tagClasses += `text-${props.textColour} `;
    }
    else {
        tagClasses += "text-white ";
    }
    if (props.bgColour) {
        tagClasses += `bg-${props.bgColour} `;
    }
    else {
        tagClasses += "bg-primary ";
    }

    return (
        <div className={tagClasses}>
            {props.text}
        </div>
    )
}

export default Tag