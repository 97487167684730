import React from "react";
import Tag from "../components/Tag";
import {Link} from "gatsby";


function CourseCardCourses(props) {
    function renderCourseTypeTag(courseType) {
        if (courseType === "free") {
            return (<Tag text={props.course.courseType.toUpperCase()} bgColour="midnight"></Tag>);
        }
        else {
            return (<Tag text={props.course.courseType.toUpperCase()} bgColour="midnight" textColour="yellow"></Tag>);
        }
    }

    let contentFormats = "";
    if (props.course.contentFormats.length) {
        contentFormats = props.course.contentFormats[0];
        if (props.course.contentFormats.length > 2) {
            contentFormats += ", " + props.course.contentFormats.slice(1, -1).join(", ");
        }
        if (props.course.contentFormats.length > 1) {
            contentFormats += " & " + props.course.contentFormats.slice(-1);
        }
    }

    const courseTag = renderCourseTypeTag(props.course.courseType);

    let cardClasses = "w-full px-4 pt-6 pb-6 desktop:pl-14 desktop:pb-10 space-y-2 ";
    if (props.bgColour) {
        cardClasses += ` bg-${props.bgColour} `;
    }
    else {
        cardClasses += " bg-secondary ";
    }
    let hoverEffects = "hover:drop-shadow-2xl hover:shadow-inner-lg hover:-translate-y-[4px]";
    cardClasses += hoverEffects

    return (
        <Link to={props.course.published ? `/${props.course.slug}` : '/coming-soon/'}>
            <div className={cardClasses}>
                <p className="font-extrabold tracking-wide">{props.course.category}</p>
                <h1 className="text-6xl font-bold py-4">{props.course.title} <sup>{courseTag}</sup></h1>
                <p>{props.course.shortDescription}</p>
                <p className="font-semibold">Content Includes: <span className="text-white font-semibold mr-2">{contentFormats}</span>
                    <i className="fa-solid fa-arrow-right-long"></i></p>
            </div>
        </Link>
    )
}

export default CourseCardCourses